import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { MenuTitleProvider } from "hooks/useMenuTitleContext";
import { SidebarProvider } from "hooks/useSideBar";
import { TabProvider } from "global/TabContext";
import { UserProvider } from "global/UserContext";
import { UserPersonProvider } from "global/UsePersonContext";
import { EditStatusProvider } from "global/EditStatusContext";
import { CandidatesProvider } from "hooks/useCandidates";
import { ReportProvider } from "global/ReportContext";
import { CandidateDiagnosticFilterProvider } from "global/CandidateDiagnosticFilterContext";
import { DiagnosisFilterProvider } from "global/DiagnosisFilterContext";
import { DrawerProvider } from "global/DrawerContext";
import { FilterVoxProvider } from "hooks/useFilterVox";
import { FilterGlobalProvider } from "hooks/useFilterGlobal";
import { TrajectoryTimeLineProvider } from "global/TrajectoryTimeLineContext";
import { TrajectoryValidVotesProvider } from "global/TrajectoryValidVotesContext";
import { TrajectoryActivitiesProvider } from "global/TrajectoryActivitiesContext";
import { TrajectoryExpensesProvider } from "global/TrajectoryExpensesContext";
import { TrajectoryDonationsProvider } from "global/TrajectoryDonationsContext";
import { HorizontalSubmenuProvider } from "global/HorizontalMenuContext";
import { MenuProvider } from "global/MenuContext";
import { IChildTypes } from "types/childrenTypes";

const queryClient = new QueryClient();

// Cluster para User, Tab e Sidebar
const CoreProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <MenuTitleProvider>
    <SidebarProvider>
      <TabProvider>
        <UserProvider>
          <UserPersonProvider>
            <EditStatusProvider>{children}</EditStatusProvider>
          </UserPersonProvider>
        </UserProvider>
      </TabProvider>
    </SidebarProvider>
  </MenuTitleProvider>
);

// Cluster para relatórios, candidatos e diagnóstico
const DataProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <CandidatesProvider>
    <ReportProvider>
      <CandidateDiagnosticFilterProvider>
        <DiagnosisFilterProvider>
          <DrawerProvider>{children}</DrawerProvider>
        </DiagnosisFilterProvider>
      </CandidateDiagnosticFilterProvider>
    </ReportProvider>
  </CandidatesProvider>
);

// Cluster para filtros
const FilterProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <FilterVoxProvider>
    <FilterGlobalProvider>{children}</FilterGlobalProvider>
  </FilterVoxProvider>
);

// Cluster para trajetória de dados
const TrajectoryProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <TrajectoryTimeLineProvider>
    <TrajectoryValidVotesProvider>
      <TrajectoryActivitiesProvider>
        <TrajectoryExpensesProvider>
          <TrajectoryDonationsProvider>{children}</TrajectoryDonationsProvider>
        </TrajectoryExpensesProvider>
      </TrajectoryActivitiesProvider>
    </TrajectoryValidVotesProvider>
  </TrajectoryTimeLineProvider>
);

// Cluster para navegação
const NavigationProviders: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => (
  <HorizontalSubmenuProvider>
    <MenuProvider>{children}</MenuProvider>
  </HorizontalSubmenuProvider>
);

const ProviderContext: React.FC<IChildTypes> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CoreProviders>
        <DataProviders>
          <FilterProviders>
            <TrajectoryProviders>
              <NavigationProviders>{children}</NavigationProviders>
            </TrajectoryProviders>
          </FilterProviders>
        </DataProviders>
      </CoreProviders>
    </QueryClientProvider>
  );
};

export default ProviderContext;
