import React, { useEffect } from "react";
import IntegratedFeed from "componentsNew/Cockpit/components/IntegratedFeed";
import { useTab } from "global/TabContext";
import * as S from "./styles";

export const Cockpit: React.FC = () => {
  const [activeTab] = React.useState("tab_cockpit");
  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.Content>
          <S.Section>
            <IntegratedFeed isCandidate />
          </S.Section>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};
