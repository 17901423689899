import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
} from "react-icons/md";
import IconFacebook from "assets/icon-facebook.svg";
import IconInstagram from "assets/icon-instagram.svg";
import IconX from "assets/icon-x.svg";
import IconYouTube from "assets/icon-youtube.svg";
import IconGoogle from "assets/icon-google.svg";
import IconDrop from "assets/icon-drop.svg";
import { apiVox } from "services/apiService";
import { Card } from "antd";
import { TotalsCount } from "componentsNew/CockpitComponents/components/TotalsCount";
import { ITotalsMentions } from "componentsNew/CockpitComponents/types/totalsMentions";
import { getSentiment } from "componentsNew/PoliticalListeningComponents/services/getListeners";
import CockpitCardMentions from "componentsNew/CockpitComponents/components/CockpitCardMentions";
import Spin from "components/Spin";
import Text from "components/Text";
import { useCandidates } from "hooks/useCandidates";
import { useFilterGlobal } from "hooks/useFilterGlobal";
import { ALL } from "utils/constants";
import {
  formatDateTime,
  handleServiceColor,
  iconRedMens,
} from "utils/utilityFunctions";
import useSocialMentionsWcCockpit from "../SocialMentionsWC/useSocialMentionsWCCockpit";
import IconActive from "../IconActive";
import LoadingSpin from "../LoadingSpin";
import theme from "styles/theme";
import * as S from "./styles";

const PAGE_SIZE = 10;

interface IPrincipalMessage {
  isCandidate?: boolean;
}

const IntegratedFeed = ({ isCandidate = false }: IPrincipalMessage) => {
  const { filterMentions, rangeDate, setInitialDate, filterRaioX } =
    useFilterGlobal();
  const { selectedCandidate } = useCandidates();
  const [mentions, setMentions] = useState([]);
  const [totalsMentions, setTotalsMentions] = useState<ITotalsMentions | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [loadingSentiment, setLoadingSentiment] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState<string>("date");

  const {
    selectedSN,
    toggleSocialNetwork,
    isLoadingPositive,
    isLoadingNegative,
    isLoadingDefault,
  } = useSocialMentionsWcCockpit();

  const [mentionsSentiment, setMentionsSentiment] = useState({
    series: [],
    options: {
      chart: {
        id: "basic-bar",
      },
      colors: ["#00E23F", "#FF0000", "#FFFFFF"],
      xaxis: {
        categories: [],
      },
    },
  });

  const fetchSentimentData = useCallback(
    async (candidateId: string) => {
      setLoadingSentiment(true);

      const socialNetworks =
        selectedSN.length > 0 ? `${selectedSN.join(",")}` : "";

      try {
        const params = {
          parent_name: filterMentions !== ALL ? filterMentions : undefined,
          date_start: rangeDate.initial_date,
          date_end: rangeDate.end_date,
          sentiment: filterRaioX !== ALL ? filterRaioX : undefined,
          candidate_id: candidateId,
          page_type_name: socialNetworks,
          id_cand: "primeclub.adm",
        };

        const data = await getSentiment(params);
        setMentionsSentiment({
          series: data.series || [],
          options: {
            chart: {
              id: "basic-bar",
            },
            colors: ["#00E23F", "#FF0000", "#FFFFFF"],
            xaxis: {
              categories: data.dates || [],
            },
          },
        });
      } catch (e) {
        console.error(e);
      } finally {
        setLoadingSentiment(false);
      }
    },
    [selectedSN, filterMentions, rangeDate, filterRaioX],
  );

  const getTopMentions = useCallback(
    async (currentPage: number) => {
      const parentName =
        filterMentions !== ALL ? `&parent_name=${filterMentions}` : "";
      const initialDate = rangeDate.initial_date
        ? `&initial_date=2010-01-01`
        : "";
      const endDate = rangeDate.end_date
        ? `&final_date=${rangeDate.end_date}`
        : "";
      const sentiment = filterRaioX !== ALL ? `&sentiment=${filterRaioX}` : "";
      const city =
        selectedCandidate?.locality && isCandidate
          ? `&city=${selectedCandidate?.locality}`
          : "";
      const targetCity =
        selectedCandidate?.target_locality && isCandidate
          ? `&target_cd_city=${selectedCandidate?.target_locality}`
          : "";
      const idCandidate = `&id_cand=primeclub.adm`;
      const socialNetworks =
        selectedSN.length > 0 ? `&page_type_name=${selectedSN.join(",")}` : "";
      const sortBy = `&sort_field=${sortField}`;
      const paginationParams = `&page=${currentPage}&page_size=${PAGE_SIZE}`;

      try {
        setLoading(true);

        const { data } = await apiVox.get(
          `/vox/v1/political-vox/top-mentions?${parentName}${initialDate}${endDate}${sentiment}${idCandidate}${socialNetworks}${sortBy}${paginationParams}`,
        );

        const total = await apiVox.get(
          `/vox/v1/political-vox/mentions-total-counter?${parentName}${initialDate}${endDate}${sentiment}${idCandidate}${socialNetworks}${targetCity}`,
        );

        const totalItems = total.data.total_mentions || 0;

        setMentions(data);
        setTotalPages(Math.ceil(totalItems / PAGE_SIZE));
        setTotalsMentions(total.data);
        setLoading(false);

        // await fetchSentimentData(selectedCandidate?.id_cand || "");
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [
      filterMentions,
      rangeDate,
      filterRaioX,
      selectedSN,
      selectedCandidate,
      isCandidate,
      fetchSentimentData,
      sortField,
      page,
    ],
  );

  useEffect(() => {
    if (rangeDate.initial_date !== "2010-01-01") {
      setInitialDate("2010-01-01");
    }

    getTopMentions(page);
  }, [page, getTopMentions, rangeDate.initial_date]);

  const anyActive = selectedSN.length > 0;

  const renderMentions = useMemo(() => {
    if (!mentions || mentions.length === 0) {
      return null;
    }

    return mentions?.map((mention: any, index: any) => {
      const service = mention?.parent_name;
      const serviceData = handleServiceColor(service);

      return (
        <CockpitCardMentions
          key={index}
          id={mention?.id_mention}
          color={serviceData?.color || "defaultColor"}
          iconSocial={iconRedMens(mention.page_type_name).icon}
          snippet={mention.snippet}
          message={mention.full_text}
          author={mention.author}
          dataCreate={formatDateTime(mention.added)}
          iconServices={serviceData?.icon}
          pageTypeName={mention.page_type_name}
          url={mention?.url}
          impact={mention.impact}
          quotesCount={mention.quotes_count}
          likesCount={mention.likes_count}
          sharesCount={mention.shares_count}
          impressionsCount={mention.impressions_count}
          interactionsCount={mention.interactions_count}
          bookmarksCount={mention.bookmarks_count}
          postsCount={mention.posts_count}
          engagementsCount={mention.engagements_count}
          imageInfo={mention.image_info}
          sentiment={mention.sentiment}
          fullWidth
        />
      );
    });
  }, [mentions]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const toggleSortField = (field: string) => {
    setSortField(field);
  };

  const isFetching = isLoadingPositive || isLoadingNegative || isLoadingDefault;

  const authorsCount = totalsMentions?.author_count || 0;
  const mentionsCount = totalsMentions?.total_mentions || 0;
  const mentionsCountCity = totalsMentions?.total_in_target || 0;
  const positiveMentionsCount = totalsMentions?.positive_mentions || 0;
  const negativeMentionsCount = totalsMentions?.negative_mentions || 0;
  const neutralMentionsCount = totalsMentions?.neutral_mentions || 0;
  const percentPositivesCount = totalsMentions?.percent_positive || 0;
  const percentNegativesCount = totalsMentions?.percent_negative || 0;
  const percentNeutralsCount = totalsMentions?.percent_neutral || 0;
  const totalImpressionsCount = totalsMentions?.total_impressions_count || 0;

  const renderPageNumbers = (totalPages: number, currentPage: number) => {
    const pageNumbers = [];

    {
      currentPage !== 1 &&
        pageNumbers.push(
          <S.PageArrow
            key="prev"
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
          >
            <MdKeyboardArrowLeft size={20} />
          </S.PageArrow>,
        );
    }

    for (let i = 1; i <= totalPages; i++) {
      if (i === 1 || i === totalPages) {
        pageNumbers.push(
          <S.PageNumber
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </S.PageNumber>,
        );
      } else if (i >= currentPage - 1 && i <= currentPage + 1) {
        pageNumbers.push(
          <S.PageNumber
            key={i}
            active={i === currentPage}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </S.PageNumber>,
        );
      } else if (i === currentPage - 2 || i === currentPage + 2) {
        pageNumbers.push(
          <Text key={`ellipsis-${i}`} color="white" bold size="medium">
            ...
          </Text>,
        );
      }
    }

    {
      currentPage !== totalPages &&
        pageNumbers.push(
          <S.PageArrow
            key="next"
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
          >
            <MdKeyboardArrowRight size={20} />
          </S.PageArrow>,
        );
    }

    return pageNumbers;
  };

  return (
    <S.Content>
      <S.StyledCard isDropdownActive={isDropdownActive}>
        <S.DropdownHeader
          onClick={() => setIsDropdownActive(!isDropdownActive)}
        >
          <S.HeaderText>
            <Text size="large" bold>
              Feed Integrado
            </Text>
          </S.HeaderText>
          <S.DropdownToggle>
            {isDropdownActive ? (
              <MdKeyboardArrowUp size={36} color={theme.COLORS.PRIMARY} />
            ) : (
              <MdKeyboardArrowDown size={36} color={theme.COLORS.PRIMARY} />
            )}
          </S.DropdownToggle>
        </S.DropdownHeader>

        {isDropdownActive && (
          <S.Wrapper>
            <S.SocialAndMentions>
              <S.FiltersHeader>
                <S.ListIcon>
                  <IconActive
                    active={false}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("Facebook")}
                    image={IconFacebook}
                    disabled={true}
                  />
                  <IconActive
                    active={selectedSN.includes("Instagram")}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("Instagram")}
                    image={IconInstagram}
                    disabled={isFetching}
                  />
                  <IconActive
                    active={false}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("X")}
                    image={IconX}
                    disabled={true}
                  />
                  <IconActive
                    active={selectedSN.includes("YouTube")}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("YouTube")}
                    image={IconYouTube}
                    disabled={isFetching}
                  />
                  <IconActive
                    active={false}
                    anyActive={anyActive}
                    onToggle={() => toggleSocialNetwork("Google")}
                    image={IconGoogle}
                    disabled={true}
                  />
                </S.ListIcon>
                <S.SortingHeader>
                  <S.TimeDropdown>
                    <S.DropdownToggle>
                      <Text size="xSmall" bold>
                        {sortField === "date"
                          ? "Mais Recentes"
                          : sortField === "impressions_count"
                            ? "Mais Visualizados"
                            : "Maior Compartilhamento"}
                      </Text>
                      <img src={IconDrop} alt="Dropdown Icon" />
                    </S.DropdownToggle>
                    <S.DropdownContent>
                      <S.DropdownItem onClick={() => toggleSortField("date")}>
                        Mais Recentes
                      </S.DropdownItem>
                      <S.DropdownItem
                        onClick={() => toggleSortField("impressions_count")}
                      >
                        Mais Visualizados
                      </S.DropdownItem>
                      <S.DropdownItem
                        onClick={() => toggleSortField("shares_count")}
                      >
                        Maior Compartilhamento
                      </S.DropdownItem>
                    </S.DropdownContent>
                  </S.TimeDropdown>
                </S.SortingHeader>
              </S.FiltersHeader>

              <S.MentionsContainer>
                {loading ? (
                  <LoadingSpin />
                ) : (
                  <>
                    <S.MentionsContainer>{renderMentions}</S.MentionsContainer>
                    {totalPages > 1 && (
                      <S.Pagination>
                        {renderPageNumbers(totalPages, page)}
                      </S.Pagination>
                    )}
                  </>
                )}
              </S.MentionsContainer>
            </S.SocialAndMentions>
            <S.TotalAndClouds>
              {loading ? (
                <Card
                  style={{
                    display: "flex",
                    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
                    border: "none",
                    width: "100%",
                    minHeight: "300px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Spin />
                </Card>
              ) : (
                <>
                  <S.TotalsCountContainer>
                    <TotalsCount
                      description="Total de Menções"
                      value={mentionsCount}
                    />
                    <TotalsCount
                      description="Visualizações"
                      value={totalImpressionsCount}
                    />
                  </S.TotalsCountContainer>
                  <S.TotalsCountContainer>
                    <TotalsCount description="" value="" />
                    <TotalsCount description="" value="" />
                  </S.TotalsCountContainer>
                  <S.TotalsCountContainer>
                    <TotalsCount description="" value="" />
                    <TotalsCount description="" value="" />
                  </S.TotalsCountContainer>
                  <S.TotalsCountContainer>
                    <TotalsCount description="" value="" />
                    <TotalsCount description="" value="" />
                  </S.TotalsCountContainer>
                </>
              )}
              <S.Clouds>
                <Card
                  style={{
                    backgroundColor: `${theme.COLORS.BACKGROUND_PRIMARY}`,
                    border: "none",
                  }}
                >
                  {loading || loadingSentiment ? (
                    <LoadingSpin height="200px" />
                  ) : (
                    <></>
                  )}
                </Card>
              </S.Clouds>
            </S.TotalAndClouds>
          </S.Wrapper>
        )}
      </S.StyledCard>
    </S.Content>
  );
};

export default IntegratedFeed;
