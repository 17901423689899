import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { GroupMember } from "../../types/groups";
import theme from "styles/theme";

interface MemberTableProps {
  members: GroupMember[];
}

const MemberTable: React.FC<MemberTableProps> = ({ members }) => (
  <TableContainer component={Paper} sx={{ width: "100%", overflow: "hidden" }}>
    <Table>
      <TableBody>
        {members.map((member, index) => (
          <TableRow
            key={member.uuid}
            sx={{
              backgroundColor:
                index % 2 === 0 ? theme.COLORS.SHADE_30 : theme.COLORS.SHADE_10,
              "&:last-child td, &:last-child th": { border: 0 },
              justifyContent: "space-between",
            }}
          >
            <TableCell
              sx={{
                padding: "0 8px",
                fontSize: "0.875rem",
              }}
            >
              {member
                ? `${member.first_name} ${member.last_name}`
                : "Nome indisponível"}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "center",
                padding: "0 8px",
              }}
            >
              {member.position ? member.position : "Posição indisponível"}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "center",
                padding: "0 8px",
              }}
            >
              Ativo em: {member.date ? member.date : "Data indisponível"}
            </TableCell>
            <TableCell
              sx={{
                textAlign: "right",
                padding: "0 8px",
                paddingRight: "2.5rem",
              }}
            >
              <IconButton onClick={() => console.log("")}>
                <MoreHorizIcon sx={{ color: theme.COLORS.PRIMARY }} />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default MemberTable;
