import React, { useEffect } from "react";
import TabsCustom from "components/Tabs";
import { useTab } from "global/TabContext";
import GovernanceThematicGroups from "pages/GovernanceThematicGroups";
import * as S from "./styles";

const labels = [
  {
    label: "Grupo Temático",
    sub_label: "",
    key: "tab_thematic_group_main",
  },
  {
    label: "Usuários",
    sub_label: "",
    key: "tab_thematic_group_users",
    disabled: true,
  },
  {
    label: "Reuniões",
    sub_label: "",
    key: "tab_thematic_group_meetings",
    disabled: true,
  },
  {
    label: "Formulários",
    sub_label: "",
    key: "tab_thematic_group_forms",
    disabled: true,
  },
];

const content: React.ReactNode[] = [
  <GovernanceThematicGroups />,
  <div></div>,
  <div></div>,
  <div></div>,
];

export const DigitalGovernance: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState("tab_thematic_group_main");
  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  const changeSettingsProfile = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content}
          onChange={(key) => changeSettingsProfile(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
