import React, { useState, useEffect, useRef } from "react";
import { Tooltip } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Text from "components/Text";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useSideBar } from "hooks/useSideBar";
import * as S from "./styles";

interface IButtonSibBar {
  isActive?: boolean;
  path: string;
  title: string;
  subtitle?: string;
  icon: React.ReactNode | any;
  subMenu: any[];
  isSubMenu?: boolean;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
}

const ButtonSidebar = ({
  isActive = false,
  path,
  title,
  subtitle = "",
  icon,
  subMenu,
  isSubMenu = false,
  label,
  onClick,
  disabled = false,
}: IButtonSibBar) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { open, setTitleMenu } = useSideBar();
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(buttonRef, () => setOpenSubMenu(false));

  const isActiveLocation =
    pathname === path || subMenu?.some((item) => item.path === pathname);

  useEffect(() => {
    if (isActiveLocation) {
      setTitleMenu(label);
    }
  }, [isActiveLocation, label, setTitleMenu]);

  const handleClick = () => {
    if (disabled) return;
    if (subMenu?.length) {
      setOpenSubMenu((prev) => !prev);
    } else if (isActive) {
      navigate(path);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <S.Container
      open={open}
      isSelected={isActiveLocation}
      isActive={isActive}
      isDisabled={disabled}
      onClick={handleClick}
      isSubMenu={isSubMenu}
      ref={buttonRef}
    >
      {isActiveLocation && <S.Bar />}
      <Tooltip title={!open && title} placement="right-start" arrow>
        <S.Wrapper>
          <img src={icon} alt={title} />
          {open && (
            <Text bold={isActiveLocation}>
              {title} {subtitle}
            </Text>
          )}
        </S.Wrapper>
      </Tooltip>
    </S.Container>
  );
};

export default ButtonSidebar;
