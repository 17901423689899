import React, { useEffect, useState } from "react";
import UserProfileCard from "components/UserProfileCard";
import TabsCustom from "components/Tabs";
import Spin from "components/Spin";
import { useTab } from "global/TabContext";
import { useCandidates } from "hooks/useCandidates";
import SelfAssessment from "pages/SelfAssessment";
import InvitesPage from "pages/UserProfile/Invites";
import * as S from "./styles";

const labels = [
  {
    label: "Auto Avaliação",
    sub_label: "",
    key: "tab_user_profile_self_assessment",
  },
  {
    label: "Indicações",
    sub_label: "",
    key: "tab_user_indications",
    disabled: false,
  },
];

const content: React.ReactNode[] = [<SelfAssessment />, <InvitesPage />];

export const UserProfile = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_user_profile_self_assessment",
  );
  const { isLoadedCandidates } = useCandidates();

  const { setActiveTabContext } = useTab();

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <UserProfileCard />
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content.map((comp) =>
            React.cloneElement(comp as React.ReactElement),
          )}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
      <S.Separator></S.Separator>
    </S.Wrapper>
  );
};
