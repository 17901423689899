import React from "react";
import { Tooltip } from "@mui/material";
import Button from "components/Button";
import Text from "components/Text";
import { useUser } from "global/UserContext";
import {
  RoomData,
  ParticipationStatus,
} from "pages/ThematicGroups/types/meetingTypes";
import * as S from "./styles";

interface RoomProps {
  roomData: RoomData;
  participantStatus: ParticipationStatus;
}

const MeetingRoom: React.FC<RoomProps> = ({ roomData, participantStatus }) => {
  const { user } = useUser();
  const userId = user?.id || "";

  if (!roomData) {
    return (
      <Text size="medium" color="red">
        Sala de reunião não encontrada.
      </Text>
    );
  }

  const iframeUrl = `${process.env.REACT_APP_URL_MEETING}/meetings/room?user=${userId}&meeting=${roomData.vendor_room_id}&pwd=${roomData.room_pwd}`;

  const openInNewWindow = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const left = screenWidth / 2 - 500;
    const top = screenHeight / 2 - 225;

    const windowFeatures = `toolbar=no, menubar=no, width=1000, height=450, top=${top}, left=${left}`;

    if (iframeUrl) {
      window.open(iframeUrl, "_blank", windowFeatures);
    }
  };

  const isDisabled = !participantStatus.is_participant;

  return (
    <S.Wrapper>
      <S.Content>
        <Tooltip
          title={
            isDisabled ? "Você não tem autorização para acessar a reunião" : ""
          }
          placement="right"
          arrow
        >
          <Button
            type="submit"
            title={`Entrar na ${roomData.title}`}
            size="small"
            variant="primary"
            onClick={openInNewWindow}
            style={{
              width: "108px",
              marginLeft: `${142.2 * roomData.position}px`,
              fontWeight: "bold",
              fontSize: "10px",
            }}
            disabled={isDisabled}
          >
            Entrar na reunião
          </Button>
        </Tooltip>
      </S.Content>
    </S.Wrapper>
  );
};

export default MeetingRoom;
