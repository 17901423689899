import React, { useEffect, useState, useCallback } from "react";
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { Button } from "antd";
import Text from "components/Text";
import { RiPencilFill } from "react-icons/ri";
import { useDebounce } from "hooks/useDebounce";
import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import { useUser } from "global/UserContext";
import { useEditStatus } from "global/EditStatusContext";
import theme from "styles/theme";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";

interface CheckboxFieldProps {
  field: I.CheckboxField;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ field }) => {
  const { user } = useUser();
  const { setEditStatus } = useEditStatus();
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [formData, setFormData] = useState<Record<string, string>>({});
  const [otherText, setOtherText] = useState<Record<string, string>>({});
  const [isOtherReadOnly, setIsOtherReadOnly] = useState<
    Record<string, boolean>
  >({});
  const debouncedFormData = useDebounce(formData, 500);
  const debouncedOtherText = useDebounce(otherText, 500);
  const [prevLoading, setPrevLoading] = useState<boolean>(true);
  const [prevError, setPrevError] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const {
    submitForm,
    loading: submissionLoading,
    error: submissionError,
  } = useSubmitForm();

  const user_id = user?.id;

  useEffect(() => {
    const pHold = field.extras?.placeholders || [];
    setPlaceholders(pHold);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchFieldSavedData(field.id, user_id);
      if (data && data.length > 0) {
        const savedSubmission = data[0];
        const savedValues = savedSubmission?.values || [];

        const otherOption = field.options.find(
          (o) => o.option_label === "Outro",
        );

        savedValues.forEach((savedValue) => {
          const optionId = savedValue?.option_id;
          const value = savedValue?.value || "";
          if (optionId) {
            setFormData((prev) => ({ ...prev, [optionId]: value }));

            if (otherOption?.id === optionId) {
              setOtherText((prev) => ({ ...prev, [optionId]: value }));
            }
          }
        });
      }
    } catch (error) {
      setPrevError("Error fetching saved data.");
      console.error(error);
    } finally {
      setPrevLoading(false);
    }
  };

  const submitData = useCallback(async () => {
    if (!isChanged) return;

    const submissionData: { values: { option_id: string; value: string }[] } = {
      values: Object.keys(formData).map((optionId) => ({
        option_id: optionId,
        value: formData[optionId],
      })),
    };
    try {
      await submitForm(field.id, submissionData);
      setIsChanged(false);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  }, [formData, isChanged]);

  useEffect(() => {
    if (isChanged) {
      submitData();
    }
  }, [debouncedFormData, debouncedOtherText, submitData]);

  const handleCheckboxChange = (
    optionId: string,
    optionLabel: string,
    checked: boolean,
  ) => {
    setEditStatus(true, optionLabel);
    setIsChanged(true);
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      if (checked) {
        updatedData[optionId] = optionLabel;
      } else {
        delete updatedData[optionId];
      }

      return updatedData;
    });
  };

  const handleOtherCheckboxChange = (optionId: string, checked: boolean) => {
    setEditStatus(true, "Outro");
    setIsChanged(true);
    setFormData((prevData) => {
      const updatedData = { ...prevData };
      if (checked) {
        updatedData[optionId] = "Outro";
        setIsOtherReadOnly((prevState) => ({
          ...prevState,
          [optionId]: false,
        }));
      } else {
        delete updatedData[optionId];
        setOtherText((prevText) => ({ ...prevText, [optionId]: "" }));
      }
      return updatedData;
    });
  };

  const handleOtherTextChange = (optionId: string, value: string) => {
    setOtherText((prev) => ({ ...prev, [optionId]: value }));
  };

  const handleBlur = (optionId: string) => {
    setIsChanged(true);
    setFormData((prevData) => ({
      ...prevData,
      [optionId]: otherText[optionId],
    }));
  };

  if (prevLoading) {
    return <div>Loading...</div>;
  }

  if (prevError) {
    return <div>Error loading data</div>;
  }

  return (
    <S.Card key={field.id}>
      <Text size="medium" bold>
        {field.label}
      </Text>
      {field.description}
      <Text color="gray" className="checkboxInfo">
        É posssível selecionar mais de um item.
      </Text>
      <FormControl component="fieldset">
        {field.options.map((option) => {
          const isOtherOption = option.option_label === "Outro";
          return (
            <S.StyledFormControlLabel key={option.id}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: theme.COLORS.TEXT_PRIMARY,
                      "&.Mui-checked": {
                        color: theme.COLORS.TEXT_PRIMARY,
                      },
                    }}
                    checked={!!formData[option.id]}
                    onChange={(e) => {
                      const checked = (e.target as HTMLInputElement).checked;

                      if (isOtherOption) {
                        handleOtherCheckboxChange(option.id, checked);
                      } else {
                        handleCheckboxChange(
                          option.id,
                          option.option_label,
                          checked,
                        );
                      }
                    }}
                  />
                }
                label={option.option_label}
                sx={{
                  alignItems: "flex-start",
                  marginTop: "8px",
                }}
              />

              {isOtherOption && !!formData[option.id] && (
                <S.Specify>
                  <TextField
                    placeholder="Especifique"
                    value={otherText[option.id] || ""}
                    onChange={(e) =>
                      handleOtherTextChange(option.id, e.target.value)
                    }
                    variant="standard"
                    InputProps={{
                      readOnly: isOtherReadOnly[option.id],
                    }}
                    onBlur={() => handleBlur(option.id)}
                  />

                  {isOtherReadOnly[option.id] && (
                    <Button
                      icon={<RiPencilFill />}
                      type="link"
                      size="small"
                      style={S.ButtonEditStyle}
                      onClick={() =>
                        setIsOtherReadOnly((prevState) => ({
                          ...prevState,
                          [option.id]: false,
                        }))
                      }
                    >
                      Editar
                    </Button>
                  )}
                </S.Specify>
              )}
            </S.StyledFormControlLabel>
          );
        })}
      </FormControl>
    </S.Card>
  );
};

export default CheckboxField;
