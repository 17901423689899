import styled from "styled-components";
import BackgroundImage from "assets/images/login/background-prime-club.jpeg";
import { BREAKPOINTS } from "styles/spacing";
import theme from "styles/theme";
import "styles/global";

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000000;
  overflow: hidden;
  background-color: ${theme.COLORS.BACKGROUND_PRIMARY};

  &::before {
    content: "";
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
    z-index: -1;
  }

  @media (max-width: ${BREAKPOINTS.WIDTH.SM}) {
    &::before {
      background-size: auto 35vh;
      background-position: top center;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  height: 100vh;
  padding-left: 15%;
  text-align: start;
  color: ${theme.COLORS.TEXT_PRIMARY};
  z-index: 1000001;
`;

export const Title = styled.span`
  color: ${theme.COLORS.TEXT_PRIMARY};
  font-family: Roboto;
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 64px;
`;

export const ContentText = styled.div`
  display: flex;
  justify-content: start;
  gap: 1rem;
  margin-top: 2rem;
`;

export const Subtitle = styled.span`
  color: ${theme.COLORS.TEXT_PRIMARY};
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const Description = styled.span`
  color: ${theme.COLORS.TEXT_PRIMARY};
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const IconWrapper = styled.div`
  margin-bottom: 1.5rem;

  svg {
    width: 96px;
    height: 96px;
    fill: ${theme.COLORS.WARNING};
  }
`;

export const Message = styled.p`
  font-size: 1.2rem;
  max-width: 500px;
  color: ${theme.COLORS.TEXT_PRIMARY};
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
`;
