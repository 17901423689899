import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Button from "components/Button";
import MemberTable from "./MemberTable";
import ViewGroupModal from "../ViewGroupModal";
import { Group } from "pages/GovernanceThematicGroups/types/groups";
import { apiOrchestrator } from "services/apiService";
import { useToast } from "hooks/useToast";
import theme from "styles/theme";

const GroupAccordion: React.FC<{ group: Group; onUpdate: () => void }> = ({
  group,
  onUpdate,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [viewGroupModalOpen, setViewGroupModalOpen] = useState(false);
  const { toastSuccess, toastError } = useToast();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenConfirm = () => {
    setConfirmOpen(true);
    handleMenuClose();
  };

  const handleCloseConfirm = () => {
    setConfirmOpen(false);
  };

  const handleDeleteGroup = async () => {
    try {
      await apiOrchestrator.delete(`/team/${group.uuid}`);
      toastSuccess("Grupo excluído com sucesso!");
      setConfirmOpen(false);
      onUpdate();
    } catch (error) {
      console.error("Erro ao excluir grupo:", error);
      toastError("Erro ao excluir grupo.");
    }
  };

  const handleViewGroup = () => {
    onUpdate();
    setViewGroupModalOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <Accordion
        sx={{
          width: "100%",
          marginBottom: 1,
          background: theme.COLORS.BACKGROUND_SECONDARY,
        }}
      >
        <AccordionSummary
          sx={{
            "& .MuiAccordionSummary-expandIconWrapper": {
              pointerEvents: "auto",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              justifyContent: "space-between",
              paddingRight: "2.5rem",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                display: "flex",
                flex: "0.33",
              }}
            >
              {group.name}
            </Typography>
            <Typography
              variant="subtitle1"
              textAlign="left"
              sx={{
                width: "100%",
                flex: "0.33",
              }}
            >
              {group.members.length}
            </Typography>
            <IconButton onClick={handleMenuOpen}>
              <MoreHorizIcon sx={{ color: theme.COLORS.PRIMARY }} />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <MemberTable members={group.members} />
        </AccordionDetails>
      </Accordion>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            background: theme.COLORS.BACKGROUND_PRIMARY,
            color: theme.COLORS.TEXT_PRIMARY,
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            padding: "2px",
          },
          "& .MuiMenuItem-root": {
            fontSize: "0.75rem",
            padding: "2px 4px",
            "&:hover": {
              backgroundColor: theme.COLORS.PRIMARY,
              color: theme.COLORS.BLACK,
              borderRadius: "4px",
            },
          },
        }}
      >
        <MenuItem onClick={handleViewGroup}>Visualizar grupo</MenuItem>
        <MenuItem onClick={handleOpenConfirm}>Excluir grupo</MenuItem>
      </Menu>

      <Dialog
        open={confirmOpen}
        onClose={handleCloseConfirm}
        PaperProps={{
          sx: {
            background: theme.COLORS.BACKGROUND_SECONDARY,
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            width: "400px",
            maxWidth: "100%",
            margin: "0 auto",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: theme.COLORS.TEXT_PRIMARY,
            fontWeight: "bold",
            fontSize: 18,
          }}
        >
          CONFIRMAÇÃO
        </DialogTitle>

        <Divider />

        <DialogContent>
          <Typography
            sx={{
              color: theme.COLORS.TEXT_PRIMARY,
              textAlign: "center",
              mb: 2,
              fontSize: "1rem",
            }}
          >
            Deseja realmente excluir o grupo temático{" "}
            <Typography component="span" sx={{ fontWeight: "bold" }}>
              {group.name}
            </Typography>
            ?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleCloseConfirm} variant="outline" fullWidth>
            Cancelar
          </Button>
          <Button onClick={handleDeleteGroup} variant="red" fullWidth>
            Excluir Grupo Temático
          </Button>
        </DialogActions>
      </Dialog>

      <ViewGroupModal
        open={viewGroupModalOpen}
        onClose={() => setViewGroupModalOpen(false)}
        groupId={group.uuid}
        groupName={group.name}
        initialData={group}
        onUpdate={onUpdate}
      />
    </>
  );
};

export default GroupAccordion;
