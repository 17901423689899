import useSelectedCandidate from "../hooks/useSelectedCandidate";

export const getMapDefaultsButtons = () => {
  const partyCandidateSelected = useSelectedCandidate();

  return [
    {
      id: "1",
      description: "Resuma as principais menções",
      type: "analyst",
      tab: "tab_politica_vox_party",
      path: "",
    },
    {
      id: "2",
      description: "Analise o gráfico de sentimento",
      type: "analyst",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "3",
      description: "Identifique os assuntos chaves",
      type: "analyst",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "4",
      description: "Identifique forças e fraquezas nas menções",
      type: "strategist",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "5",
      description: "Identifique ameças e oportunidades",
      type: "strategist",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "6",
      description: "Faça uma análise SWOT com base nas menções",
      type: "strategist",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "7",
      description: "Resuma os resultados eleitorais",
      type: "analyst",
      tab: "tab_election_diagnosis_result",
      path: "/dashboard/political-intelligence/election-diagnosis",
    },
    {
      id: "8",
      description: `Em quais bairros o ${partyCandidateSelected} ganhou?`,
      type: "analyst",
      tab: "tab_election_diagnosis_result",
      path: "/dashboard/political-intelligence/election-diagnosis",
    },
    {
      id: "9",
      description: "Quais partidos tiveram maior dispersão de votos?",
      type: "analyst",
      tab: "tab_election_diagnosis_result",
      path: "/dashboard/political-intelligence/election-diagnosis",
    },
    {
      id: "10",
      description: "Quais são os bairros mais competitivos?",
      type: "strategist",
      tab: "tab_election_diagnosis_result",
      path: "/dashboard/political-intelligence/election-diagnosis",
    },
    {
      id: "11",
      description: `Quais os bairros mais relevantes para o ${partyCandidateSelected}?`,
      type: "strategist",
      tab: "tab_election_diagnosis_result",
      path: "/dashboard/political-intelligence/election-diagnosis",
    },
    {
      id: "12",
      description: `Sugira estratégias para o ${partyCandidateSelected} com base nos resultados eleitorais`,
      type: "strategist",
      tab: "tab_election_diagnosis_result",
      path: "/dashboard/political-intelligence/election-diagnosis",
    },
    {
      id: "13",
      description: "Crie mensagens chaves com base nas menções",
      type: "marketing",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "14",
      description: "Elabore pontos chaves para reverter menções negativas",
      type: "marketing",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "15",
      description: "A partir das menções e SWOT, crie mensagens chaves",
      type: "marketing",
      tab: "tab_politica_vox_party",
      path: "/dashboard/political-vox",
    },
    {
      id: "16",
      description: "Crie mensagens chaves com base nas menções",
      type: "marketing",
      tab: "tab_voter_diagnosis_profile",
      path: "/dashboard/political-vox",
    },
    {
      id: "17",
      description: "Elabore pontos chaves para reverter menções negativas",
      type: "marketing",
      tab: "tab_voter_diagnosis_profile",
      path: "/dashboard/political-vox",
    },
    {
      id: "18",
      description: "A partir das menções e SWOT, crie mensagens chaves",
      type: "marketing",
      tab: "tab_voter_diagnosis_profile",
      path: "/dashboard/political-vox",
    },
    {
      id: "19",
      description: "Resuma as principais menções",
      type: "analyst",
      tab: "tab_politica_vox_search",
      path: "",
    },
    {
      id: "20",
      description: "Analise o gráfico de sentimento",
      type: "analyst",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
    {
      id: "21",
      description: "Identifique os assuntos chaves",
      type: "analyst",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
    {
      id: "22",
      description: "Identifique forças e fraquezas nas menções",
      type: "strategist",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
    {
      id: "23",
      description: "Identifique ameças e oportunidades",
      type: "strategist",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
    {
      id: "24",
      description: "Faça uma análise SWOT com base nas menções",
      type: "strategist",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
    {
      id: "25",
      description: "Crie mensagens chaves com base nas menções",
      type: "marketing",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
    {
      id: "26",
      description: "Elabore pontos chaves para reverter menções negativas",
      type: "marketing",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
    {
      id: "27",
      description: "A partir das menções e SWOT, crie mensagens chaves",
      type: "marketing",
      tab: "tab_politica_vox_search",
      path: "/dashboard/political-vox",
    },
  ];
};
