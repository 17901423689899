import { ReactComponent as IconAnalystAdvisor } from "assets/advisors/icon-analyst-advisor.svg";
import { ReactComponent as IconStrategistAdvisor } from "assets/advisors/icon-strategist-advisor.svg";
import { ReactComponent as IconMarketingAdvisor } from "assets/advisors/icon-marketing-advisor.svg";
import { ReactComponent as IconResearcherAdvisor } from "assets/advisors/icon-researcher-advisor.svg";
import { ReactComponent as IconHelpDeskAdvisor } from "assets/advisors/icon-help-desk-advisor.svg";

export const mapSidebarAdvisor = [
  {
    name: "Liderança",
    type: "advisor",
    title: ``,
    icon: IconAnalystAdvisor,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Governança",
    type: "advisor",
    icon: IconStrategistAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Lifelong Learning",
    type: "advisor",
    icon: IconResearcherAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Conexões Institucionais",
    type: "advisor",
    icon: IconMarketingAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Curadoria",
    type: "advisor",
    icon: IconStrategistAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Chamadas Agendadas",
    type: "advisor",
    icon: IconHelpDeskAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
];
