import React from "react";
import { useFetchForms } from "hooks/useFetchForms";
import DynamicForm from "components/DynamicForm";
import Text from "components/Text";
import Spin from "components/Spin";
import PersonalInfoForm from "components/PersonalInfoForm";
import TermComponent from "components/Term";
import TitleFormComponent from "components/TitleForm";
import { DataProtectionTerm } from "./utils/dataProtectionTerm";
import { useUser } from "global/UserContext";
import { useSocialNetworks } from "components/SocialNetworkField/hooks/useSocialNetworks";
import SocialNetworkFields from "./components/SocialNetworkFields";
import * as S from "./styles";

const SelfAssessment: React.FC = () => {
  const { user } = useUser();
  const {
    forms,
    loading: formsLoading,
    error,
  } = useFetchForms("2a6bd2b4-713f-4208-bbe1-c188d6942ac5");

  const { networkTypes, loading: networksLoading } = useSocialNetworks();

  const { title, subtitle, description, content, version } = DataProtectionTerm;
  const uuid = user?.id;

  if (formsLoading || networksLoading) return <Spin fullscreen />;
  if (error)
    return (
      <Text color="red" bold>
        Erro ao carregar formulário: {error}
      </Text>
    );

  return (
    <S.Container>
      <TermComponent
        title={title}
        subtitle={subtitle}
        description={description}
        content={content}
        version={version}
      />
      <S.PersonalDataContainer>
        <TitleFormComponent title="Dados Pessoais" />
        <PersonalInfoForm />
        <SocialNetworkFields networkTypes={networkTypes} />
      </S.PersonalDataContainer>
      {forms.map((form) => (
        <S.Container key={form.id}>
          <TitleFormComponent title={form?.form_name} />
          <DynamicForm form_id={form.id} uuid={uuid} />
        </S.Container>
      ))}
    </S.Container>
  );
};

export default SelfAssessment;
