import styled from "styled-components";
import theme from "styles/theme";

export const IconWrapper = styled.div<{
  active: boolean;
  disabled: boolean;
  anyActive: boolean;
}>`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border: 4px solid;
  overflow: hidden;
  border-color: ${({ active }) =>
    active ? theme.COLORS.PRIMARY : "transparent"};
  opacity: ${({ active, anyActive }) => (anyActive ? (active ? 1 : 0.5) : 1)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export const Icon = styled.img<{ active: boolean; anyActive: boolean }>`
  width: 100%;
  object-fit: cover;
  opacity: ${({ active, anyActive }) => (anyActive ? (active ? 1 : 0.5) : 1)};
`;
