import styled from "styled-components";
import theme from "styles/theme";

export const InviteListContainer = styled.div`
  .MuiTableContainer-root {
    box-shadow: none;
  }

  .MuiTableHead-root tr {
    border-radius: 8px;
  }

  .MuiTable-root {
    border-collapse: separate;
    border-spacing: 0 4px;
    border: transparent;
  }

  .MuiTable-root th,
  .MuiTable-root td {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }

  .MuiTable-root tr {
    background-color: ${theme.COLORS.BACKGROUND_SECONDARY};
    border-radius: 8px;
  }
`;
