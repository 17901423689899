import { Feature, UserPermissions } from "types/userPlanFeaturesTypes";
import { ROUTES, FEATURE_TO_ROUTE_MAP } from "utils/constants";
import * as Icon from "utils/utilsIcons";

export interface SidebarMenuItem {
  title: string;
  label: string;
  path: string;
  icon: string;
  isActive: boolean;
  rank?: number;
  subtitle?: string;
  subMenu?: SidebarMenuItem[];
}

const iconMapping: Record<string, string> = {
  Cockpit: Icon.IconCockpit,
  Comunidade: Icon.IconCommunity,
  ClubeDashboards: Icon.IconClub,
  Educacao: Icon.IconEducation,
  PerfilComunidade: Icon.IconProfileCommunity,
  InteligenciaNetowrking: Icon.IconIntelligenceNetworking,
  GruposTematicos: Icon.IconThematicGroups,
  OrquestracaoComunidade: Icon.IconCommunityOrchestrator,
  GovernancaDigital: Icon.IconDigitalGovernance,
  PerfilAvaliacoes: Icon.IconProfileAndAssessments,
  RodadasAtivacao: Icon.IconActivationRounds,
  PerfilPrimeCLub: Icon.IconProfileCommunity,
  InteligenciaConexoes: Icon.IconIntelligenceNetworking,
  AtivacaoConexoes: Icon.IconLinkSolid,
  OrquestracaoRede: Icon.IconOrchestration,
  NetworkingPratica: Icon.IconChats,
  CuradoriaDados: ROUTES.NOT_FOUND,
  Advisors: ROUTES.NOT_FOUND,
};

const hasPermission = (
  restrictionPolicy: { code: string; name: string } | null | undefined,
  permissions: string[],
): boolean => {
  if (!restrictionPolicy) return true;
  return permissions.includes(restrictionPolicy.code);
};

export const generateSidebarMenuFromFeatures = (
  features: Feature[],
  permissions: UserPermissions,
): SidebarMenuItem[] => {
  const sidebarItems = features
    .filter(
      (feature) =>
        feature.is_sidebar &&
        (!feature.is_restrict ||
          hasPermission(feature.restriction_policy, permissions)),
    )
    .map((feature) => ({
      title: feature.name,
      label: feature.name,
      path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[feature.code] || "not-found"}`,
      icon: iconMapping[feature.code] || "",
      isActive: feature.is_active,
      rank: feature.rank,
      subMenu: feature.children?.length
        ? feature.children
            .filter(
              (child) =>
                child.is_sidebar &&
                (!child.is_restrict ||
                  hasPermission(child.restriction_policy, permissions)),
            )
            .map((child) => ({
              title: child.name,
              label: child.name,
              path: `${ROUTES.ROOT}${FEATURE_TO_ROUTE_MAP[child.code] || "not-found"}`,
              icon: iconMapping[child.code] || "",
              isActive: child.is_active,
              rank: child.rank,
            }))
            .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0))
        : undefined,
    }))
    .sort((a, b) => (a.rank ?? 0) - (b.rank ?? 0));

  return sidebarItems;
};
