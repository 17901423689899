import styled from "styled-components";
import theme from "styles/theme";
import "styles/global";

export const Wrapper = styled.main<{ backgroundImage: string }>`
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)),
    url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  color: ${theme.COLORS.GRAY_10};
`;

export const Content = styled.div`
  width: 100%;

  @media screen and (min-width: 1190px) {
    max-width: 1200px;
    min-width: 1024px;
  }
`;

export const ImgContent = styled.div`
  height: 80%;
  justify-items: end;
  display: flex;
  align-items: end;
  float: right;
`;
