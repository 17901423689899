import React, { useEffect, useState } from "react";
import TabsCustom from "components/Tabs";
import Spin from "components/Spin";
import PBIDash from "componentsNew/PBIDash";
import { useTab } from "global/TabContext";
import { useDiagnosisFilter } from "global/DiagnosisFilterContext";
import { useCandidates } from "hooks/useCandidates";
import * as S from "./styles";

const labels = [
  {
    label: "Perfil Geral ",
    sub_label: "Membros",
    key: "tab_intelligence_networking_general_networking",
  },
  {
    label: "Bio Individual ",
    sub_label: "Membros",
    key: "tab_intelligence_networking_bio_networks",
  },
  {
    label: "Comunidade  ",
    sub_label: "Estendida",
    key: "tab_intelligence_networking_extended_community",
  },
  {
    label: "Rede Individual ",
    sub_label: "Membros",
    key: "tab_intelligence_networking_individual_network",
  },
  {
    label: "Perfil Geral ",
    sub_label: "Convidados",
    key: "tab_intelligence_networking_general_profile_invited",
  },
];

const content: React.ReactNode[] = [
  <PBIDash reportName="primeclub_community_general_networks" />,
  <PBIDash reportName="primeclub_community_bio_networks" />,
  <PBIDash reportName="primeclub_community_extended_community" />,
  <PBIDash reportName="primeclub_community_individual_network" />,
  <PBIDash reportName="primeclub_community_general_invitee_profile" />,
];

export const IntelligenceNetworking = () => {
  const [activeTab, setActiveTab] = React.useState(
    "tab_intelligence_networking_general_networking",
  );
  const { isLoadedCandidates } = useCandidates();
  const { setActiveTabContext } = useTab();
  const { filters } = useDiagnosisFilter();
  const [filterKey, setFilterKey] = useState(Date.now()); // Key for PBIDash

  useEffect(() => {
    setActiveTabContext(activeTab);
  }, [activeTab, setActiveTabContext]);

  useEffect(() => {
    setFilterKey(Date.now());
  }, [filters]);

  if (isLoadedCandidates) {
    return <Spin fullscreen />;
  }

  const changePoliticalTab = (item: string) => {
    setActiveTab(item);
    setActiveTabContext(item);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <TabsCustom
          title=""
          labels={labels}
          activeTab={activeTab}
          content={content.map((comp, index) =>
            React.cloneElement(comp as React.ReactElement, {
              key: `${filterKey}-${index}`,
            }),
          )}
          onChange={(key) => changePoliticalTab(key)}
        />
      </S.Content>
    </S.Wrapper>
  );
};
