import { ReactComponent as IconAnalystAdvisor } from "assets/advisors/icon-analyst-advisor.svg";
import { ReactComponent as IconStrategistAdvisor } from "assets/advisors/icon-strategist-advisor.svg";
import { ReactComponent as IconHelpDeskAdvisor } from "assets/advisors/icon-help-desk-advisor.svg";

export const mapSidebarMentorship = [
  {
    name: "Mentoria Networking",
    type: "advisor",
    title: ``,
    icon: IconAnalystAdvisor,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Mentoria Carreira",
    type: "advisor",
    title: ``,
    icon: IconStrategistAdvisor,
    filter: false,
    path: "",
    disabled: false,
  },
  {
    name: "Chamadas Agendadas",
    type: "advisor",
    icon: IconHelpDeskAdvisor,
    title: ``,
    filter: false,
    path: "",
    disabled: false,
  },
];
