import { useState, useEffect } from "react";
import { apiOrchestrator } from "services/apiService";

const useProfileImage = (userId: string) => {
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        setLoading(true);
        const response = await apiOrchestrator.get(
          `/users-person/get-image/${userId}`,
          {
            responseType: "blob",
          },
        );
        const imageUrl = URL.createObjectURL(response.data);
        setProfileImage(imageUrl);
      } catch (error) {
        console.error("Failed to fetch profile image:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchProfileImage();
    }
  }, [userId]);

  const updateProfileImage = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true);
      const response = await apiOrchestrator.post(
        `/users-person/upload-image/?user_id=${userId}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );
      if (response.data.status === "success") {
        setProfileImage(URL.createObjectURL(file));
      } else {
        throw new Error("Image upload failed");
      }
    } catch (error) {
      console.error("Failed to upload profile image:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteProfileImage = async () => {
    try {
      setLoading(true);
      await apiOrchestrator.put(
        `/users-person/delete-image/?user_id=${userId}`,
      );
      setProfileImage(null);
    } catch (error) {
      console.error("Failed to delete profile image:", error);
    } finally {
      setLoading(false);
    }
  };

  return { profileImage, updateProfileImage, deleteProfileImage, loading };
};

export default useProfileImage;
