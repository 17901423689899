import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import Text from "components/Text";
import { useSubmitForm } from "../useSubmitForm";
import { fetchFieldSavedData } from "../helpers/fetchFieldSavedData";
import {
  fetchStates,
  fetchCitiesByState,
  City,
  State,
} from "services/geoService";
import { useUser } from "global/UserContext";
import { useEditStatus } from "global/EditStatusContext";
import { useDebounce } from "hooks/useDebounce";
import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "../styles";

const GeoField: React.FC<I.BaseField> = ({
  form_id,
  label,
  is_required,
  id,
  field_type,
  description,
  extras,
}) => {
  const { user } = useUser();
  const { setEditStatus } = useEditStatus();
  const [placeholders, setPlaceholders] = useState<string[]>([]);
  const [loadingLocality, setLoadingLocality] = useState(true);
  const [states, setStates] = useState<State[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [prevLoading, setPrevLoading] = useState<boolean>(true);
  const [prevError, setPrevError] = useState<string | null>(null);

  const {
    submitForm,
    loading: submissionLoading,
    error: submissionError,
  } = useSubmitForm();

  const user_id = user?.id;

  const debouncedFormData = useDebounce(formData, 500);

  useEffect(() => {
    const pHold = extras?.placeholders || [];
    setPlaceholders(pHold);
    fetchData();
  }, [id, user_id]);

  const fetchData = async () => {
    setPrevLoading(true);
    const data = await fetchFieldSavedData(id, user_id);
    const fetchedStates = await fetchStates();
    setStates(fetchedStates);

    if (data) {
      const savedSubmission = data[0];
      const savedValue = savedSubmission.values?.[0]?.value;
      if (savedValue) {
        const [savedStateLabel, savedCityLabel] = savedValue.split(" | ");

        const selectedState = fetchedStates.find(
          (state) => state.label === savedStateLabel,
        );

        if (selectedState) {
          const fetchedCities = await fetchCitiesByState(selectedState.value);
          const selectedCity = fetchedCities.find(
            (city) => city.label === savedCityLabel,
          );
          setCities(fetchedCities);
          setFormData({
            geo_state: selectedState.value,
            geo_city: selectedCity ? selectedCity.value : "",
          });
        }
      }
    }

    setPrevLoading(false);
  };

  const handleStateChange = async (event: SelectChangeEvent<string>) => {
    const selectedState = event.target.value as string;
    setEditStatus(true, selectedState);
    setFormData({ geo_state: selectedState, geo_city: "" });

    const fetchedCities = await fetchCitiesByState(selectedState);
    setCities(fetchedCities);
  };

  const handleCityChange = (event: SelectChangeEvent<string>) => {
    const selectedCity = event.target.value as string;
    setEditStatus(true, selectedCity);
    setFormData((prevData) => ({
      ...prevData,
      geo_city: selectedCity,
    }));
  };

  useEffect(() => {
    const submitData = async () => {
      const { geo_state, geo_city } = debouncedFormData;
      if (geo_state && geo_city) {
        await handleSubmit(geo_state, geo_city);
      }
    };

    if (debouncedFormData.geo_state && debouncedFormData.geo_city) {
      submitData();
    }
  }, [debouncedFormData]);

  const handleSubmit = async (geo_state: string, geo_city: string) => {
    const selectedState = states.find((state) => state.value === geo_state);
    const selectedCity = cities.find((city) => city.value === geo_city);

    if (selectedState && selectedCity) {
      const submittedData = `${selectedState.label} | ${selectedCity.label}`;

      const submissionData = {
        values: [{ value: submittedData }],
      };

      await submitForm(id, submissionData);
    } else {
      console.error("State or city not selected");
    }
  };

  if (prevLoading) {
    return <div>Loading...</div>;
  }

  if (prevError) {
    return <div>Error loading data</div>;
  }

  if (!placeholders) {
    return <div />;
  }

  return (
    <Stack>
      <Text size="medium" color="gray">
        {description}
      </Text>
      <S.GroupRow>
        <S.CardGroup>
          <S.Card>
            <Text size="medium" bold>
              Selecione o Estado
            </Text>
            <FormControl fullWidth>
              <Select
                value={formData.geo_state || ""}
                onChange={handleStateChange}
              >
                {states.map((state) => (
                  <MenuItem key={state.value} value={state.value}>
                    {state.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.Card>

          <S.Card>
            <Text size="medium" bold>
              Selecione a Cidade
            </Text>
            <FormControl fullWidth>
              <Select
                value={formData.geo_city || ""}
                disabled={!cities.length}
                onChange={handleCityChange}
              >
                {cities.map((city) => (
                  <MenuItem key={city.value} value={city.value}>
                    {city.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </S.Card>
        </S.CardGroup>
      </S.GroupRow>
    </Stack>
  );
};

export default GeoField;
