import React, { useEffect, useState } from "react";
import { TextField, CircularProgress } from "@mui/material";
import { useSocialNetworks } from "./hooks/useSocialNetworks";
import { useEditStatus } from "global/EditStatusContext";
import _ from "lodash";
import theme from "styles/theme";

interface ISocialNetworkField {
  socialNetworkType: string;
  placeholder: string;
}

const SocialNetworkField: React.FC<ISocialNetworkField> = ({
  socialNetworkType,
  placeholder,
}) => {
  const { upsertSocialNetwork, getUsernameByType, loading } =
    useSocialNetworks();
  const { setEditStatus } = useEditStatus();
  const existingNetwork = getUsernameByType(socialNetworkType);
  const originalValue = existingNetwork?.social_network_username || "";
  const [username, setUsername] = useState(originalValue);
  const [isEditing, setIsEditing] = useState(false);

  const debouncedUpdate = _.debounce((value: string) => {
    if (value.trim() !== originalValue) {
      if (value.trim() === "") {
        if (existingNetwork) {
          upsertSocialNetwork({
            ...existingNetwork,
            social_network_username: "",
          });
        }
      } else {
        upsertSocialNetwork({
          social_network_type: socialNetworkType,
          social_network_username: value,
        });
      }
      setEditStatus(false, "");
      setIsEditing(false);
    } else {
      setEditStatus(false, "");
      setIsEditing(false);
    }
  }, 3000);

  useEffect(() => {
    if (username !== originalValue && !isEditing) {
      setEditStatus(true, username);
      setIsEditing(true);
    }
    debouncedUpdate(username);

    return () => {
      debouncedUpdate.cancel();
    };
  }, [username]);

  useEffect(() => {
    setUsername(originalValue);
  }, [existingNetwork]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {loading ? (
        <CircularProgress size={24} style={{ color: theme.COLORS.PRIMARY }} />
      ) : (
        <TextField
          variant="outlined"
          label={placeholder}
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
          fullWidth
        />
      )}
    </div>
  );
};

export default SocialNetworkField;
