import React from "react";

import { useFetchFields } from "hooks/useFetchFields";
import Text from "components/Text";

import * as I from "components/DynamicForm/types/dynamicFormTypes";
import * as S from "./styles";
import TagField from "./fields/TagField";
import GeoField from "./fields/GeoField";
import RadioField from "./fields/RadioField";
import CheckboxField from "./fields/CheckboxField";
import TextFormField from "./fields/TextFormField";

const DynamicForm: React.FC<I.DynamicFormProps> = ({ form_id, uuid }) => {
  const { fields, loading, error } = useFetchFields(form_id);

  if (loading)
    return (
      <Text size="xSmall" bold>
        Carregando campos...
      </Text>
    );
  if (error)
    return (
      <Text size="xSmall" color="red" bold>
        Erro ao carregar campos: {error}
      </Text>
    );

  return (
    <S.ContainerForm>
      <form style={{ width: "100%" }}>
        <S.GridContainer
          isTagsLayout={fields.some(
            (field) =>
              field.field_type === "tags" || field.field_type === "geo_state",
          )}
        >
          {fields.map((field) => {
            if (field.field_type === "geo_state") {
              return (
                <GeoField
                  form_id={field.form_id}
                  label={field.label}
                  is_required={field.is_required}
                  id={field.id}
                  field_type={field.field_type}
                  description={field.description}
                  extras={field.extras}
                />
              );
            }

            if (field.field_type === "tags") {
              return (
                <TagField
                  form_id={field.form_id}
                  label={field.label}
                  is_required={field.is_required}
                  id={field.id}
                  field_type={field.field_type}
                  description={field.description}
                  extras={field.extras}
                />
              );
            }

            if (field.field_type === "textarea") {
              return <TextFormField field={field} />;
            }

            if (field.field_type === "dropdown") {
              return <RadioField field={field} />;
            }

            if (field.field_type === "checkbox") {
              return <CheckboxField field={field} />;
            }
            return null;
          })}
        </S.GridContainer>
      </form>
    </S.ContainerForm>
  );
};

export default DynamicForm;
