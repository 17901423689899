import React from "react";
import { Modal } from "antd";
import Button from "components/Button";
import Text from "components/Text";
import * as S from "../styles";

interface SuccessModalProps {
  isOpen: boolean;
  onClose: () => void;
  inviteeName?: string | null;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isOpen,
  onClose,
  inviteeName,
}) => {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      closable={false}
      maskClosable={false}
      footer={null}
      width={400}
      style={{
        textAlign: "center",
      }}
    >
      <Text size="large" bold>
        CONFIRMAÇÃO
      </Text>
      <S.Line />
      <S.ModalContent>
        <Text size="medium">
          Cadastro realizado com sucesso! <br />
          {inviteeName && (
            <>
              <strong>{inviteeName}</strong> agradece pelo aceite do convite.
            </>
          )}
        </Text>
        <Button onClick={onClose} variant="primary" color="primary" fullWidth>
          Fechar
        </Button>
      </S.ModalContent>
    </Modal>
  );
};

export default SuccessModal;
