import React, {
  forwardRef,
  ButtonHTMLAttributes,
  AnchorHTMLAttributes,
} from "react";
import { MdEdit } from "react-icons/md";

import * as S from "./styles";

type ButtonSize = "small" | "medium" | "large";

type Variant = "outline" | "minimal" | "primary";

type ButtonTypes =
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | ButtonHTMLAttributes<HTMLButtonElement>;

export type InternalButtonProps = {
  size?: ButtonSize;
  icon?: React.ReactNode;
  background: string;
  children?: React.ReactNode;
  variant?: Variant;
  iconPosition?: "right" | "left";
  disabled?: boolean;
  textColor?: string;
  as?: React.ElementType;
} & ButtonTypes;

const InternalButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  InternalButtonProps
>(
  (
    {
      children,
      icon = <MdEdit />,
      size = "medium",
      background,
      disabled,
      variant = "primary",
      iconPosition = "left",
      textColor,
      ...props
    },
    ref,
  ) => {
    return (
      <S.Wrapper
        textColor={textColor}
        size={size}
        hasIcon={!!icon}
        background={background}
        ref={ref}
        variant={variant}
        disabled={disabled}
        {...props}
      >
        {icon && iconPosition === "left" && icon}
        {children && <span>{children}</span>}
        {icon && iconPosition === "right" && icon}
      </S.Wrapper>
    );
  },
);

InternalButton.defaultProps = {
  size: "medium",
};

export default InternalButton;
