const AUTH_TOKEN = "authToken";

const ROUTES = {
  AUTH: {
    LOGIN: "/login",
    LOGOUT: "/logout",
    REGISTER: "/register",
    FORGOT_PASSWORD: "/forgot-password",
    RECOVER_PASSWORD: "/recover-password",
  },
  ROOT: "/dashboard/",
  COCKPIT: "cockpit",
  CLUB_PRIME_CLUB_PROFILE: "club/prime-profile",
  CLUB_INTELLIGENCE_CONNECTIONS: "club/intelligence-connections",
  CLUB_ACTIVATION_ROUNDS: "club/activation-rounds",
  CLUB_ACTIVATION_CONNECTIONS: "club/activation-connections",
  CLUB_NETWORKING_ORCHESTRATION: "club/orchestration",
  COMMUNITY_PROFILE: "community/community-profile",
  COMMUNITY_INTELLIGENCE_NETWORKING: "community/intelligence-networking",
  COMMUNITY_THEMATIC_GROUPS: "community/thematic-groups",
  DIGITAL_GOVERNANCE: "digital-governance",
  USER: {
    PROFILE: "user-profile",
    SETTINGS: "settings/profile",
  },
  NOT_FOUND: "not-found",

  GOALS_TIMELINE: "trajectory/goals/timeline",
  GOALS_VALID_VOTES: "trajectory/goals/valid-votes",
  ACQUISITIONS_SIGNATURE: "acquisitions/signature/order",

  MANAGEMENT_TEAM: "application-management/team",
};

export const FEATURE_TO_ROUTE_MAP: Record<string, string> = {
  Cockpit: ROUTES.COCKPIT,
  PerfilComunidade: ROUTES.COMMUNITY_PROFILE,
  InteligenciaNetowrking: ROUTES.COMMUNITY_INTELLIGENCE_NETWORKING,
  GruposTematicos: ROUTES.COMMUNITY_THEMATIC_GROUPS,
  RodadasAtivacao: ROUTES.CLUB_ACTIVATION_ROUNDS,
  PerfilPrimeCLub: ROUTES.CLUB_PRIME_CLUB_PROFILE,
  InteligenciaConexoes: ROUTES.CLUB_INTELLIGENCE_CONNECTIONS,
  AtivacaoConexoes: ROUTES.CLUB_ACTIVATION_CONNECTIONS,
  OrquestracaoRede: ROUTES.CLUB_NETWORKING_ORCHESTRATION,
  NetworkingPratica: ROUTES.NOT_FOUND,
  Educacao: ROUTES.NOT_FOUND,
  PerfilAvaliacoes: ROUTES.USER.PROFILE,
  GovernancaDigital: ROUTES.DIGITAL_GOVERNANCE,
  CuradoriaDados: ROUTES.NOT_FOUND,
  Advisors: ROUTES.NOT_FOUND,
};

const POSITIVE = "positive";
const NEGATIVE = "negative";
const ALL = "all";

export { AUTH_TOKEN, ROUTES, POSITIVE, NEGATIVE, ALL };
