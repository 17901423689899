import React, { useState } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import GroupAccordion from "./GroupCard/GroupAccordion";
import { Group } from "../types/groups";
import theme from "styles/theme";

interface GroupListProps {
  groups: Group[];
  searchTerm: string;
  onUpdate: () => void;
}

export const GroupList: React.FC<GroupListProps> = ({
  groups,
  searchTerm,
  onUpdate,
}) => {
  const [isAscending, setIsAscending] = useState(true);

  const handleSortToggle = () => {
    setIsAscending(!isAscending);
  };

  const filteredAndSortedGroups = groups
    .filter((group) =>
      group.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) =>
      isAscending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name),
    );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0 8px",
          backgroundColor: theme.COLORS.BACKGROUND_SECONDARY,
          color: theme.COLORS.TEXT_PRIMARY,
          borderRadius: "4px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flex: "0.33" }}>
          <Typography variant="caption">Nome</Typography>
          <IconButton onClick={handleSortToggle} size="small">
            {isAscending ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
        <Typography
          variant="caption"
          sx={{ flex: "0.33", textAlign: "center" }}
        >
          Usuários ativos
        </Typography>
        <Typography
          variant="caption"
          sx={{ flex: "0.34", textAlign: "right", marginRight: "3.5rem" }}
        >
          Ações
        </Typography>
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        {filteredAndSortedGroups.map((group) => (
          <GroupAccordion key={group.uuid} group={group} onUpdate={onUpdate} />
        ))}
      </Box>
    </>
  );
};

export default GroupList;
