import React, { useEffect, useState } from "react";
import { TextField, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Text from "components/Text";
import InternalButton from "components/InternalButton";
import CreateGroupModal from "./components/CreateGroupModal";
import GroupList from "./components/GroupList";
import { apiOrchestrator } from "services/apiService";
import theme from "styles/theme";
import * as S from "./styles";

const GovernanceThematicGroups: React.FC = () => {
  const [groups, setGroups] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchGroups = async () => {
    try {
      const response = await apiOrchestrator.get("/team/all");
      setGroups(response.data);
    } catch (error) {
      console.error("Erro ao buscar grupos:", error);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        my: 0,
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "0.5rem",
        }}
      >
        <Text size="large">Grupos Temáticos ({groups.length})</Text>
        <Box sx={{ display: "flex", gap: "1rem", flex: "0.4" }}>
          <Box sx={{ display: "flex", flex: "0.7" }}>
            <TextField
              variant="outlined"
              placeholder="Buscar por Grupo Temático"
              value={searchTerm}
              onChange={handleSearchChange}
              size="small"
              fullWidth
              sx={S.SearchService}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "0.3",
              alignItems: "center",
            }}
          >
            <InternalButton
              onClick={() => setOpenModal(true)}
              size="large"
              background={theme.COLORS.PRIMARY}
              variant="outline"
              iconPosition="left"
              textColor={theme.COLORS.TEXT_PRIMARY}
              icon={<AddIcon sx={{ fontSize: "auto" }} />}
            >
              Criar novo Grupo Temático
            </InternalButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <GroupList
          groups={groups}
          searchTerm={searchTerm}
          onUpdate={fetchGroups}
        />

        <CreateGroupModal
          open={openModal}
          onClose={() => setOpenModal(false)}
          onGroupCreated={(groupId) => {}}
          onUpdate={fetchGroups}
        />
      </Box>
    </Box>
  );
};

export default GovernanceThematicGroups;
